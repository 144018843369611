import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { SelectLanguage } from "./SelectLanguage";
import { invertColorAndDarken } from "../UtilsComponents/GetInvertColor";

export const MainFooter = (props) => {
    const { backColor } = props;
    var year = new Date().getFullYear();
    const backColorInverted = invertColorAndDarken(backColor);

    return (
        <div className="footer-basic" style={{ backgroundColor: backColor }}>
            <footer>
                <div className="social" style={{ padding: "0px 0% 0px 7%" }}>
                    <a target="blank" href="https://www.instagram.com/7.bytes/">
                        <i className="fa fa-instagram" style={{ color: backColorInverted }} />
                    </a>
                    <a target="blank" href="https://www.facebook.com/7Bytes.co">
                        <i className="fa fa-facebook" style={{ color: backColorInverted }} />
                    </a>
                    <a target="blank" href="https://www.youtube.com/channel/UCY8a1Mk7voJKgnTgUrNcgdw">
                        <i className="fa fa-youtube" style={{ color: backColorInverted }} />
                    </a>
                    <a target="blank" href="https://wa.link/d9qeok">
                        <i className="fa fa-whatsapp" style={{ color: backColorInverted }} />
                    </a>
                    <SelectLanguage styles={{ marginLeft: "auto", marginRight: 35, float: "right", color: backColorInverted }} />
                </div>
                <ul className="list-inline">
                    <li className="list-inline-item" style={{ marginLeft: 35, marginRight: "auto", float: "left" }}>
                    </li>
                    <li className="list-inline-item" style={{ color: backColorInverted }}>
                        <Link to="/">
                            <FormattedMessage
                                id="navbar.home"
                                defaultMessage="Inicio"
                            />
                        </Link>
                    </li>
                    <li className="list-inline-item" style={{ color: backColorInverted }}>
                        <Link to="/aboutUs">
                            <FormattedMessage
                                id="navbar.aboutUs"
                                defaultMessage="Quienes somos"
                            />
                        </Link>
                    </li>
                    <li className="list-inline-item" style={{ color: backColorInverted }}>
                        <Link to="/contact">
                            <FormattedMessage
                                id="navbar.contact"
                                defaultMessage="Contacto"
                            />
                        </Link>
                    </li>
                    <li className="list-inline-item" style={{ color: backColorInverted }}>
                        <Link to="/dataPolicy">
                            <FormattedMessage
                                id="navbar.dataPolicy"
                                defaultMessage="Política de datos"
                            />
                        </Link>
                    </li>
                </ul>
                <p className="copyright" style={{ color: backColorInverted }}>OCXCI - 7Bytes © {year}</p>
            </footer>
        </div>
    );
};