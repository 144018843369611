import { useRef, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useAuth from "../../CustomHooks/useAuth";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "../../api/axios";
import * as Sentry from "@sentry/browser";
import { useNavigate, useLocation, Link } from "react-router-dom";
import useLocalStorage from "../../CustomHooks/useLocalStorage";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MetaDecorator from "../UtilsComponents/MetaDecorator";
import { FilledInput, FormControl, IconButton, InputAdornment, InputLabel } from "@mui/material";
const LOGIN_URL = "auth";

export const Login = () => {

    const { REACT_APP_CAPTCHA_SITE_KEY_LOGIN } = process.env;
    const { setIsAuth, persist, setPersist, setIsLogin } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = "/dashboard" || location.state?.from?.pathname;
    const intl = useIntl();

    const emailRef = useRef();
    const errRef = useRef();
    const recaptchaRef = useRef();

    const [isSending, setIsSending] = useState(false);
    const [email, setEmail] = useLocalStorage("email", "");
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        //Esto solo funciona cuando se envuelve el componente en un <PersistLogin />
        // if (isAuth?.accessToken) {
        //     return navigate("/");
        // }
        emailRef.current.focus();
        return () => {
            setErrMsg('');
        };
    }, []);

    const getUserIp = async () => {
        try {
            const response = await axios.get("https://api.ipify.org/?format=json");
            return response.data.ip;
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        setErrMsg('');
    }, [email, pwd]);

    window.recaptchaOptions = {
        useRecaptchaNet: true,
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!window.grecaptcha) {
            setErrMsg("Captcha Error. " + intl.formatMessage({ id: "error.captcha.info" }));
            return;
        }
        const token = await recaptchaRef.current.executeAsync();
        try {
            setIsSending(true);
            const userIp = await getUserIp();
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ email, pwd, token, userIp, from: "login" }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            // console.log(JSON.stringify(response?.data));
            const accessToken = response?.data?.accessToken;
            // const role = response?.data?.role;
            setIsAuth({ accessToken });
            setIsLogin(true);
            setEmail('');
            setPwd('');
            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                Sentry.captureMessage(err.toString() + " Error interno en el  servidor");
                // Sentry.captureMessage(err.text+"Error al iniciar sesión");
                setErrMsg(
                    <FormattedMessage
                        id="login.Error.No.Server"
                        defaultMessage={`Error interno en el  servidor`}
                    />
                );
            } else if (err.response?.status === 400) {
                if (err.response?.data?.text === "Captcha no validado") {
                    console.error(err);
                    Sentry.captureMessage("Error en el captcha: Login");
                    setErrMsg(
                        <FormattedMessage
                            id="error.captcha"
                            defaultMessage={`Error en el captcha`}
                        />
                    );
                }
                else {
                    setErrMsg(
                        <FormattedMessage
                            id="login.Error.Missing.Fields"
                        />
                    );
                }
            } else if (err.response?.status === 401) {
                if (err.response?.data?.text === "End free trial") {
                    const btnModal = document.getElementById("btnModal");
                    btnModal.click();
                } else {
                    setErrMsg(
                        <FormattedMessage
                            id="login.Error.Invalid.Credentials"
                            defaultMessage={`Correo electrónico o contraseña no válidos`}
                        />
                    );
                }
            } else {
                console.error(err);
                Sentry.captureException(err, "Error al iniciar sesión");
                setErrMsg(
                    <FormattedMessage
                        id="login.Error.Failed"
                        defaultMessage={`Fallo al momento de iniciar sesión`}
                    />
                );
            }
            errRef.current.focus();
        }
        finally {
            setIsSending(false);
        }
    }

    const handleCaptcha = async (value) => {
        try {
            //reset recaptcha
            recaptchaRef.current.reset();
        } catch (err) {
            console.error(err);
        }
    }

    const togglePersist = () => {
        setPersist(!persist);
    }

    useEffect(() => {
        setPersist(persist);
        return () => setPersist(persist);
    }, [persist, setPersist]);

    return (
        <section className=" py-5"
            style={{
                background: "Linear-gradient(45deg, #051B29, #09989E 80%)",
                minHeight: "100vh"
            }}>
            <MetaDecorator
                title={intl.formatMessage({ id: "navbar.login" })}
                description={intl.formatMessage({ id: "login.Text" }).substring(0, 170)}
                imageUrl={"/images/7Bytes.png"}
                imageAlt={"Login"}
                path={window.location.pathname}
            />
            <div className="modal fade" id="alertModal" tabIndex="-1" aria-labelledby="alertModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <svg style={{ width: "17px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z" /></svg>&nbsp;
                            <h5 className="modal-title" id="alertModalLabel" style={{ color: "#F55937" }}>
                                <FormattedMessage
                                    id="alert.title"
                                />
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-danger d-flex align-items-center" role="alert">
                                <div>
                                    <FormattedMessage
                                        id="end.free.trial"
                                    />
                                </div>
                            </div>
                            <a target="blank" style={{ color: "green" }} href="https://wa.link/ykiqzl">
                                <strong>
                                    <FormattedMessage id="contact.msg" /> <i className="fa fa-whatsapp" style={{ color: "green" }} ></i>
                                </strong>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-0 Login" >
                <div className="row d-flex py-2 justify-content-center align-items-center h-100">
                    <div className="text-center" >
                        <h2 className="fw-normal "
                            style={{
                                color: "#ffff",
                                letterSpacing: 1,
                                fontSize: 40
                            }}>
                            <strong>
                                <FormattedMessage
                                    id="login.Title"
                                    defaultMessage={`Acceder a su cuenta`}
                                />
                            </strong>
                        </h2>
                        <h4 className="fw-normal"
                            style={{ color: "#ffff", letterSpacing: 1 }} >
                            <FormattedMessage
                                id="login.SubTitle"
                                defaultMessage={`Bienvenido a 7Bytes`}
                            />
                        </h4>
                        <h4 className="fw-normal"
                            style={{ color: "#ffff", letterSpacing: 1 }} >
                            <FormattedMessage
                                id="login.Text"
                                defaultMessage={`Estudio de mercado continuo`}
                            />
                        </h4>
                    </div>
                    <div className="row mt-5" id="mainLogin" style={{ margin: "" }}>
                        <img src="/images/7Bytes sin slogan.png" className="loginForm mt-3" style={{ width: "300px" }} alt="logo 7bytes" />
                        {/* Btn to activate the modal */}
                        <button style={{ display: "none" }} type="button" id="btnModal" className="glow-on-hover" data-bs-toggle="modal" data-bs-target="#alertModal">
                            <i className="fa fa-plus" style={{ color: "whitesmoke" }} data-bs-toggle="modal" data-bs-target="#alertModal" /> &nbsp;
                            <FormattedMessage
                                id="create.db.btn"
                                defaultMessage={`Crear nueva base de datos`}
                            />
                        </button>
                        {/* ----------------------- */}
                        <div className="col-md-12 mt-3 align-items-center" >
                            <div className="text-black" >
                                <form onSubmit={handleSubmit} >
                                    <div className="form-floating mb-3 loginForm">
                                        <input
                                            ref={emailRef}
                                            type="email"
                                            className="form-control"
                                            style={{ borderRadius: "10px" }}
                                            id="inputemail"
                                            autoComplete="off"
                                            placeholder="Email"
                                            required
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                        />
                                        <label htmlFor="floatingInputEmail">
                                            <i className="fa fa-envelope" style={{ color: "gray" }} /> &nbsp;
                                            <FormattedMessage
                                                id="login.email"
                                                defaultMessage={`Email`}
                                            />
                                        </label>
                                    </div>
                                    <div className="form-floating mb-3 loginForm">
                                        <FormControl fullWidth sx={{ borderRadius: "10px", backgroundColor: "white" }} variant="filled">
                                            <InputLabel htmlFor="inputpassword" style={{ color: "black" }}>
                                                <i className="fa fa-key" style={{ color: "gray" }} /> <FormattedMessage id="login.password" />
                                            </InputLabel>
                                            <FilledInput
                                                onChange={(e) => setPwd(e.target.value)}
                                                value={pwd}
                                                required
                                                id="inputpassword"
                                                type={showPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => { setShowPassword(!showPassword) }}
                                                            onMouseDown={(e) => { e.preventDefault() }}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </div>
                                    <div className="row">
                                        <div className="form-check form-switch align-items-center loginForm">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="persist"
                                                onChange={togglePersist}
                                                checked={persist}
                                            />
                                            <label className="form-check-label" style={{ color: "whitesmoke" }} htmlFor="persist">
                                                <FormattedMessage
                                                    id="login.Persist.Text"
                                                    defaultMessage={`Confiar en este dispositivo`}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    {/* <div className="align-items-center loginForm">
                                        <input
                                            type="checkbox"
                                            id="persist"
                                            onChange={togglePersist}
                                            checked={persist}
                                        />
                                        <label htmlFor="persist">Recordar </label>
                                    </div> */}
                                    <div className="align-items-center loginForm">
                                        <strong ref={errRef} className={errMsg ? "errmsg" : "offscreen"} style={{ color: "red", fontSize: "20px" }} aria-live="assertive">{errMsg}</strong>
                                    </div>
                                    <ReCAPTCHA
                                        className="g-recaptcha"
                                        ref={recaptchaRef}
                                        size="invisible"
                                        sitekey={REACT_APP_CAPTCHA_SITE_KEY_LOGIN}
                                        onChange={handleCaptcha}
                                    />
                                    <div className="d-grid gap-2 mt-2 mb-3">
                                        <button className="btn btn-primary form-control loginFormBtn" disabled={isSending ? true : false} style={{ background: "#051B29" }} type="submit">
                                            <FormattedMessage
                                                id="login.button"
                                                defaultMessage={`Ingresar`}
                                            />
                                        </button>
                                    </div>
                                    <hr style={{ backgroundColor: "whitesmoke" }} />
                                    <div className="align-items-center loginForm">
                                        <div className="row">
                                            <div className="col-md-7">
                                                <Link to="/register" style={{ color: "#1d999e" }}>
                                                    <h5>
                                                        <FormattedMessage
                                                            id="register.new.account"
                                                        />
                                                    </h5>
                                                </Link>
                                            </div>
                                            <div className="col-md-5">
                                                <Link to="/forgot-password" style={{ color: "#1d999e", cursor: "pointer !important" }}>
                                                    <i >
                                                        <FormattedMessage
                                                            id="forgot.password"
                                                        />
                                                    </i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* <div
                        className="text-center"
                        style={{
                            color: "#ffff",
                            letterSpacing: 1,
                        }}
                    >
                        <p>
                            <a className="small text-muted" href="#!">
                                Forgot password?
                            </a>
                        </p>
                        <p>
                            <a href="#!" className="small text-muted">
                                Terms of use.
                            </a>
                            <a href="#!" className="small text-muted">
                                Privacy policy
                            </a>
                        </p>
                    </div> */}
                </div>
            </div>
        </section>
    );
};