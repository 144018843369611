import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Footer } from '../../ReuseComponents/Footer'
import template from "../../../Domains/customers.json";
import { FormattedMessage, useIntl } from 'react-intl';
import { SurveyLoadingSkeleton } from '../UtilsComponents/SurveyLoadingSkeleton';
import { ViewQuestion } from './ViewQuestion';
import { languageContext } from '../../../context/languageContext';
import { TransitionLeft, topRightPosition } from '../../UtilsComponents/SnackBarTransitions';
import { Alert, Snackbar, Tooltip } from '@mui/material';
import useAxiosPrivate from '../../../CustomHooks/useAxiosPrivate';
import { useQuery } from '@tanstack/react-query';
import { EditQuestionModal } from '../EditQuestionsComponents/EditQuestionModal';
import { CreateNewQuestion } from './CreateNewQuestion';
import MetaDecorator from '../../UtilsComponents/MetaDecorator';
import useAuth from '../../../CustomHooks/useAuth';
import { TabsViewSurvey } from './TabsViewSurvey';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ShuffleOutlinedIcon from '@mui/icons-material/ShuffleOutlined';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import { NavbarViewSurvey } from '../UtilsComponents/NavbarViewSurvey';
import StylesContext from '../UtilsComponents/SytlesContextViewSurvey';
import { SurveyTitles } from './SurveyTitles';
import handleCapture from '../../UtilsComponents/SurveyScreenShot';

const myStyles = {
    fontSize: "19px"
}

const mandatory = <PriorityHighOutlinedIcon style={myStyles} />;
const fixed = <LockOutlinedIcon style={myStyles} />;
const dynamic = <ShuffleOutlinedIcon style={myStyles} />;


export const ViewSurvey = () => {
    const maxCharsTextArea = 150;
    const maxCharsTextBox = 70;
    //get query params
    const query = new URLSearchParams(window.location.search);
    const { defaultCustomer } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const survey = query.get("survey");
    const language = useContext(languageContext);
    const intl = useIntl();
    const [alertMsg, setAlertMsg] = useState("");
    const [countFixed, setCountFixed] = useState(0);
    const [countRequired, setCountRequired] = useState(0);
    const [countDynamic, setCountDynamic] = useState(0);

    const { data: resCustomerStyle, isLoading: loadingStyles, isError: errorStyles } = useQuery({
        queryKey: ["customerStyle", defaultCustomer],
        queryFn: async () => {
            const response = await axiosPrivate.get(`customer/info/${defaultCustomer}`)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: false,
    });
    const { data: resQuestions, isLoading: loadingQuestions, isError: errorQuestions, isRefetching: isRefetchingQuestions } = useQuery({
        queryKey: ["surveyQuestions", survey],
        queryFn: async () => {
            const response = await axiosPrivate.get(`survey/info/${survey}?customer=${defaultCustomer}`)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: false,
    });

    const source = useMemo(() => {
        return resQuestions?.source
    }, [resQuestions]);

    const [snackBarState, setSnackBarState] = React.useState(topRightPosition);
    const { vertical, horizontal, open } = snackBarState;
    const {
        url,
        logo,
        headerBackground,
        bodyBackground,
        fontColor,
        title,
        subtitle,
        subtitleColor,
        btnSubmit
    } = resCustomerStyle ? resCustomerStyle[defaultCustomer.toLowerCase()] : template["7bytes"];
    document.body.style.backgroundColor = bodyBackground;

    const [surveyLogo, setSurveyLogo] = useState(logo);
    const [submitLogo, setSubmitLogo] = useState();
    const [headerColor, setHeaderColor] = useState(headerBackground);
    const [bodyColor, setBodyColor] = useState(bodyBackground);
    const [btnColorBackground, setBtnColorBackground] = useState(btnSubmit.bg);
    const [btnColorText, setBtnColorText] = useState(btnSubmit.color);
    const [surveyTitleColor, setSurveyTitleColor] = useState(fontColor);
    const [surveySubtitleColor, setSurveySubtitleColor] = useState(subtitleColor);


    useEffect(() => {
        setSurveyLogo(logo);
        setHeaderColor(headerBackground);
        setBodyColor(bodyBackground);
        setBtnColorBackground(btnSubmit.bg);
        setBtnColorText(btnSubmit.color);
        setSurveyTitleColor(fontColor);
        setSurveySubtitleColor(subtitleColor ? subtitleColor : "#808090");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resCustomerStyle]);

    const { data: limitations, error: errorLimitations } = useQuery({
        queryKey: ["limitations", defaultCustomer],
        queryFn: async () => {
            const response = await axiosPrivate.get(`survey/limit/${defaultCustomer}`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        retry: false,
    });

    const validateQuestionLimitation = () => {
        if (errorLimitations) {
            alert(
                intl.formatMessage({ id: "survey.limitations.error" })
            );
            return true;
        }
        if (limitations?.max_survey_item === "unlimited") return false;
        else if (limitations?.max_survey_item <= resQuestions?.total_questions) {
            alert(
                intl.formatMessage({ id: "survey.question.limit.msg" })
                + "\n" +
                intl.formatMessage({ id: "survey.upgrade.plan.msg" })
            );
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (resQuestions?.multiLanguage === false) {
            setSnackBarState({ ...snackBarState, open: true });
            setAlertMsg(
                <FormattedMessage
                    id="survey.view.language.warning"
                    defaultMessage={`Es posible que algunas preguntas no esten disponibles en varios idiomas`}
                />
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl])

    useEffect(() => {
        const count = resQuestions?.questions?.filter(question => question.question_type === 1).length;
        setCountFixed(count);
        setCountRequired(resQuestions?.questions?.filter(question => question.required === true).length);
        setCountDynamic(resQuestions?.questions?.filter(question => question.question_type === 3).length);
        if (count > resQuestions?.show_questions) {
            setSnackBarState({ ...snackBarState, open: true });
            setAlertMsg(
                <FormattedMessage
                    id="edit.too.many.fixed"
                    defaultMessage={`Hay muchas preguntas fijas por lo que se mostraran todas esas preguntas en la encuesta`}
                    values={
                        {
                            count
                        }
                    }
                />
            );
        }
    }, [resQuestions])


    useEffect(() => {
        if (errorStyles) alert(intl.formatMessage({ id: "survey.view.styles.error" }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorStyles])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarState({ ...snackBarState, open: false });
    };
    const [openModal, setOpenModal] = useState(false);
    const [editQuestion, setEditQuestion] = useState(null);

    const captureRef = useRef(null);

    // useEffect(() => {
    //     console.log(!(loadingStyles || loadingQuestions || errorQuestions));
    //     !(loadingStyles || loadingQuestions || errorQuestions) && handleCapture(captureRef);
    // }, [loadingStyles, loadingQuestions, errorQuestions]);

    return (
        (loadingStyles || loadingQuestions || errorQuestions) ?
            <SurveyLoadingSkeleton errorMsg={errorQuestions && intl.formatMessage({ id: "survey.view.question.error" })} /> :
            <StylesContext.Provider
                value={
                    {
                        source: resQuestions?.source,
                        stylesToCompare: resCustomerStyle ? resCustomerStyle[defaultCustomer.toLowerCase()] : null,
                        originalLogo: logo,
                        submitLogo,
                        setSubmitLogo,
                        surveyLogo,
                        setSurveyLogo,
                        headerColor,
                        setHeaderColor,
                        bodyColor,
                        setBodyColor,
                        btnColorBackground,
                        setBtnColorBackground,
                        btnColorText,
                        setBtnColorText,
                        surveyTitleColor,
                        setSurveyTitleColor,
                        surveySubtitleColor,
                        setSurveySubtitleColor
                    }
                }
            >
                <NavbarViewSurvey />
                {/* <button onClick={() => {handleCapture(captureRef)}}>Capture</button> */}
                <table ref={captureRef} border="0" cellPadding="0" cellSpacing="0" width="100%" >
                    <MetaDecorator
                        title={`Encuesta - ${source}`}
                        description={`Mi encuestas ${source}`}
                        imageUrl={"/images/7Bytes.png"}
                        imageAlt={`Mi encuestas ${source}`}
                        path={window.location.pathname}
                    />
                    <tbody>
                        <tr>
                            <td bgcolor={headerColor} align="center">
                                <table className="tableWith">
                                    <tbody>
                                        <tr>
                                            <td className="td1" align="center" valign="top">
                                                <a target="_blank" rel="noreferrer" href={url}>
                                                    <img className="" src={surveyLogo} style={{ maxHeight: "129px", maxWidth: "400px" }} alt="Logo" />
                                                    {/* <img className="img1" alt="Logo" src="\images\Logo.png" /> */}
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td className="td2" bgcolor={headerColor}  >
                                <table className="tableWith" align="center"  >
                                    <tbody>
                                        <tr>
                                            <td className="tdTitle" align="center"  >
                                                <EditQuestionModal
                                                    open={openModal}
                                                    customerName={defaultCustomer}
                                                    setOpenModal={setOpenModal}
                                                    editQuestion={editQuestion}
                                                    validateQuestionLimitation={validateQuestionLimitation}
                                                    maxCharsTextBox={maxCharsTextBox}
                                                    maxCharsTextArea={maxCharsTextArea}
                                                />
                                                <Snackbar
                                                    open={open}
                                                    autoHideDuration={10000}
                                                    onClose={handleClose}
                                                    TransitionComponent={TransitionLeft}
                                                    anchorOrigin={{ vertical, horizontal }}
                                                    key={vertical + horizontal}>
                                                    <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
                                                        {alertMsg}
                                                    </Alert>
                                                </Snackbar>
                                                <SurveyTitles title={title ? title : intl.formatMessage({ id: "styles.change.title" })}
                                                    subtitle={subtitle ? subtitle : intl.formatMessage({ id: "styles.change.subtitle" })} />
                                                {/* {idShop=== "" ?  <h3>Cargando...</h3>: idShop !== "No encontrado" && <h3>Por favor ingresa tus datos en la parte inferior</h3>} */}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td className="tdBody" bgcolor={bodyColor} align="center" >
                                <table className="tableWith mb-5 shadow2" >
                                    <tbody>
                                        <tr>
                                            <td className="tdBody2" align="left"  >
                                                <TabsViewSurvey>
                                                    {
                                                        resQuestions?.questions?.length > 0 &&
                                                        <h6>
                                                            <FormattedMessage
                                                                id="edit.survey.question.marks.info"
                                                                defaultMessage={`Fija [!] - Obligatoria [*] - Dinamica [~]`}
                                                                values={{
                                                                    countFixed,
                                                                    countRequired,
                                                                    countDynamic,
                                                                    mandatory,
                                                                    fixed,
                                                                    dynamic
                                                                }}
                                                            />
                                                        </h6>
                                                    }
                                                    {resQuestions?.questions ?
                                                        <ViewQuestion
                                                            questions={resQuestions.questions}
                                                            setEditQuestion={setEditQuestion}
                                                            setOpenModal={setOpenModal}
                                                            subQuestions={resQuestions?.dependentQuestions}
                                                            language={language.locale}
                                                            isRefetchingQuestions={isRefetchingQuestions}
                                                            validateQuestionLimitation={validateQuestionLimitation}
                                                            maxCharsTextBox={maxCharsTextBox}
                                                            maxCharsTextArea={maxCharsTextArea}
                                                        />
                                                        :
                                                        <CreateNewQuestion
                                                            setEditQuestion={setEditQuestion}
                                                            setOpenModal={setOpenModal}
                                                        />
                                                    }
                                                </TabsViewSurvey >
                                                <br />
                                                {
                                                    resQuestions?.questions?.length > 0 &&
                                                    <div className="row mt-3">
                                                        <div className="col text-center">
                                                            <Tooltip title={"Boton de demostracion"} placement="top">
                                                                <button type="submit" style={{ backgroundColor: btnColorBackground }} className="btn btn-lg shadow">
                                                                    <strong style={{ color: btnColorText }}>
                                                                        <FormattedMessage id="send.button" defaultMessage="Enviar" />
                                                                    </strong>
                                                                </button>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <Footer bodyBackground={bodyColor} fontColor={surveyTitleColor} />
                        </tr>
                    </tbody>
                </table >
            </StylesContext.Provider>
    )
}
