import React, { useEffect, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button, LinearProgress, MenuItem, Skeleton, Tooltip } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { FormattedMessage, useIntl } from "react-intl";
import useAxiosPrivate from '../../../CustomHooks/useAxiosPrivate';
import { TableLanguages } from '../../../Languages/TableLanguages';
import { Delete, Edit } from '@mui/icons-material';
// import PreviewIcon from '@mui/icons-material/Preview';
import { useNavigate } from 'react-router-dom';
import { SnackBarComponent } from '../../UtilsComponents/SnackBarComponent';
import { topCenterPosition, TransitionDown } from '../../UtilsComponents/SnackBarTransitions';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CreateSurveyDialog } from '../CreateSurveyComponents/CreateSurveyDialog';
import AddIcon from '@mui/icons-material/Add';
import MetaDecorator from '../../UtilsComponents/MetaDecorator';
import useAuth from '../../../CustomHooks/useAuth';
import { QrDialog } from '../../ReuseComponents/QrDialog';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { TableLoadingSkeleton } from '../../ReuseComponents/TableLoadingSkeleton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const MySurveysTable = () => {
    const { defaultCustomer, isViewRole } = useAuth();
    const url = `survey?customer=${defaultCustomer}`
    const navigate = useNavigate();
    const intl = useIntl();
    const axiosPrivate = useAxiosPrivate();
    const actionLanguage = TableLanguages();
    const [errorMsg, setErrorMsg] = useState();
    const [tableData, setTableData] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openDialogSnackBar, setOpenDialogSnackBar] = useState(false);
    const [color, setColor] = useState("success");
    const [msg, setMsg] = useState("");
    const [columns, setColumns] = useState([]);
    const [change, setChange] = useState(false);
    const queryClient = useQueryClient();

    const [validationErrors, setValidationErrors] = useState({});
    const [openCreate, setOpenCreate] = React.useState(false);
    document.body.style.backgroundColor = "#66b7ba";
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const updateSurvey = async ({ row, values }) => {
        const response = await axiosPrivate.put(`survey/${row.original.id}?customer=${defaultCustomer}`, values);
        return response.data;
    }
    const updateSurveyMutation = useMutation({
        mutationFn: updateSurvey,
        onSuccess: (data, { row, values }) => {
            tableData[row.index] = values;
            setTableData([...tableData]);
            setColor("success");
            setMsg(intl.formatMessage({ id: "table.survey.update.success" }));
            setOpenSnackBar(true);
            queryClient.invalidateQueries(["table", url], { exact: true });
        },
        onError: (error) => {
            if (error.response.status === 400) {
                setMsg("ERROR: " + intl.formatMessage({ id: "survey.create.exists.error" }));
            } else {
                setMsg("ERROR: " + intl.formatMessage({ id: "survey.update.error" }));
            }
            setColor("error");
            setOpenSnackBar(true);
            console.error(error);
        }
    });
    
    const createSurveyMutation = useMutation({
        mutationFn: async (values) => {
            setChange(true);
            const response = await axiosPrivate.post(`survey?customer=${defaultCustomer}`, values);
            return response.data;
        },
        onSuccess: (data, values) => {
            tableData.push(values);
            setTableData([...tableData]);
            setColor("success");
            setMsg(intl.formatMessage({ id: "survey.create.success" }));
            setOpenDialogSnackBar(true);
            queryClient.invalidateQueries(["table", url], { exact: true });
            setChange(false);
        },
        onError: (error) => {
            if (error.response.status === 400) {
                setMsg("ERROR: " + intl.formatMessage({ id: "survey.create.exists.error" }));
            } else {
                setMsg("ERROR: " + intl.formatMessage({ id: "survey.create.error" }));
            }
            setColor("error");
            setOpenDialogSnackBar(true);
            setChange(false);
            console.error(error);
        }
    });

    const deleteSurveyMutation = useMutation({
        mutationFn: async (row) => {
            setChange(true);
            const response = await axiosPrivate.delete(
                `/survey/${row.original.id}?customer=${defaultCustomer}`
            )
            return response.data;
        },
        onSuccess: (data, row) => {
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
            setChange(false);
            setMsg(intl.formatMessage({ id: "item.deleted.msg" }));
            setColor("success");
            setOpenSnackBar(true);
            queryClient.invalidateQueries(["table", url], { exact: true });
        },
        onError: (error) => {
            setChange(false);
            setColor("error");
            setMsg(intl.formatMessage({ id: "delete.item.error.msg" }));
            setOpenSnackBar(true);
            console.error(error);
        }
    });

    const getData = async () => {
        const response = await axiosPrivate.get(url);
        return response.data;
    }

    const { data, isLoading, isError, error } = useQuery({
        queryKey: ["table", url],
        queryFn: getData,
        refetchOnWindowFocus: false,
        retry: false,
    });

    const { data: limitations, error: errorLimitations } = useQuery({
        queryKey: ["limitations", defaultCustomer],
        queryFn: async () => {
            const response = await axiosPrivate.get(`survey/limit/${defaultCustomer}`);
            return response.data;
        },
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 60 * 60 * 24 * 1000,
        cacheTime: 60 * 60 * 24 * 1000,
    });

    const validateCreateLimitation = () => {
        if (errorLimitations) {
            alert(
                intl.formatMessage({ id: "survey.limitations.error" })
            );
            setOpenCreate(false);
        }
        if (limitations?.max_survey === "unlimited") return false;
        else if (limitations?.max_survey <= data?.length) {
            alert(
                intl.formatMessage({ id: "survey.limit.msg" })
                + "\n" +
                intl.formatMessage({ id: "survey.upgrade.plan.msg" })
            );
            setOpenCreate(false);
        } else {
            return false;
        }
        return true;
    }

    useEffect(() => {
        openCreate && validateCreateLimitation();
        // eslint-disable-next-line
    }, [openCreate]);

    useEffect(() => {
        if (isError) {
            setErrorMsg(isError);
            setColor("error");
            setMsg(intl.formatMessage({ id: "get.data.error.msg" }));
            setOpenSnackBar(true);
            console.error(error);
        } else {
            setErrorMsg(null);
        }
        // eslint-disable-next-line
    }, [isError])

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            if (row.original.source === values.source) {
                values.equals = true;
            } else {
                values.equals = false;
            }
            if (url.includes("?customer")) {
                updateSurveyMutation.mutate({ row, values });
            }
            exitEditingMode(); //required to exit editing mode and close modal
        }
    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const getCommonEditTextFieldProps = ((cell) => {
        return {
            error: !!validationErrors[cell.id],
            helperText: validationErrors[cell.id],
            onBlur: (event) => {
                const [isValid, errorMsg] = surveyValidation(cell.column.id, event.target.value)
                if (!isValid) {
                    //set validation error for cell if invalid
                    setValidationErrors({
                        ...validationErrors,
                        [cell.id]: `${errorMsg}`,
                    });
                } else {
                    //remove validation error for cell if valid
                    delete validationErrors[cell.id];
                    setValidationErrors({
                        ...validationErrors,
                    });
                }
            },
        };
    });

    const surveyValidation = (columnName, value) => {
        const specialCharacters = /[!@#ñÑ$%^&*()_+=[\]{};':"\\|,.<>/?]+/;
        const tilde = /[áéíóúÁÉÍÓÚ´]+/;
        if (url.includes("?customer")) {
            if (columnName === "items") {
                return [parseInt(value) >= 1 && parseInt(value) <= 30, intl.formatMessage({ id: "table.items.empty" })]
            }
            else if (columnName === "survey_name") {
                return [value.length >= 3 && value.length <= 50, intl.formatMessage({ id: "table.survey.name.length" })]
            } else if (columnName === "source") {
                if (value.includes(" ")) {
                    return [false, intl.formatMessage({ id: "register.url.name.space" })]
                } else if (specialCharacters.test(value) || tilde.test(value)) {
                    return [false, intl.formatMessage({ id: "register.store.url.name.special.char" })]
                } else {
                    return [value.length >= 3 && value.length <= 50, intl.formatMessage({ id: "table.survey.source.length" })]
                }
            }
        }
        return [true, ""];
    };

    const columnsName = {
        "survey_name": {
            header: intl.formatMessage({ id: "survey.source.name" }),
            Header: <Tooltip arrow placement="top" title={intl.formatMessage({ id: "survey.source.name.description" })}>
                <span>
                    <FormattedMessage id="survey.source.name" />
                </span>
            </Tooltip>
        },
        "source": {
            header: intl.formatMessage({ id: "survey.source" }),
            Header: <Tooltip arrow placement="top" title={intl.formatMessage({ id: "survey.source.description" })}>
                <span>
                    <FormattedMessage id="survey.source" />
                </span>
            </Tooltip>
        },
        "items": {
            header: intl.formatMessage({ id: "survey.visible.questions" }),
            Header: <Tooltip arrow placement="top" title={intl.formatMessage({ id: "survey.visible.questions.description" })}>
                <span>
                    <FormattedMessage id="survey.visible.questions" />
                </span>
            </Tooltip>
        },
        "enabled": {
            header: intl.formatMessage({ id: "survey.enabled" }),
            Header: <Tooltip arrow placement="top" title={intl.formatMessage({ id: "survey.enabled.description" })}>
                <span>
                    <FormattedMessage id="survey.enabled" />
                </span>
            </Tooltip>
        },
        "count": {
            header: intl.formatMessage({ id: "answered.number" }),
            Header: <Tooltip arrow placement="top" title={intl.formatMessage({ id: "answered.number.description" })}>
                <span>
                    <FormattedMessage id="answered.number" />
                </span>
            </Tooltip>
        },
        "creation_date": {
            header: intl.formatMessage({ id: "survey.creation.date" }),
            Header: <Tooltip arrow placement="top" title={intl.formatMessage({ id: "survey.creation.date.description" })}>
                <span>
                    <FormattedMessage id="survey.creation.date" />
                </span>
            </Tooltip>
        },
    };

    useEffect(() => {
        let columns = [];
        if (!isLoading && Object.keys(data)?.length > 0) {
            const boolArray = [intl.formatMessage({ id: "yes" }), intl.formatMessage({ id: "no" })]
            columns = Object.entries(data[0]).map(([key, value]) => {
                let size = 130;
                let editField = ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                    variant: 'outlined',
                });
                let filterVariant = "text";
                let filterSelectOptions = []
                let columnFilterModeOptions = ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals', 'notEquals', "empty", "notEmpty"]
                if (key === "items") {
                    size = 80
                    filterVariant = "number";
                    columnFilterModeOptions = ['equals', 'notEquals', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual']
                    editField = ({ cell }) => ({
                        ...getCommonEditTextFieldProps(cell),
                        type: 'number',
                        variant: 'outlined',
                    })
                } if (typeof value === 'boolean') {
                    size = 90;
                    filterVariant = "select";
                    filterSelectOptions = [{ text: intl.formatMessage({ id: "yes" }), value: true }, { text: intl.formatMessage({ id: "no" }), value: false }]
                    columnFilterModeOptions = []
                    editField = {
                        select: true, //change to select for a dropdown
                        children: boolArray.map((option) => (
                            <MenuItem key={option} value={option === intl.formatMessage({ id: "yes" }) ? true : false}>
                                {option}
                            </MenuItem>
                        )),
                        variant: 'outlined',
                    }
                }

                return {
                    accessorKey: key,
                    header: columnsName[key]?.header,
                    Header: ({ column }) => (
                        columnsName[key]?.Header
                    ),
                    enableEditing: (key === "count" || key === "creation_date") ? false : true,
                    enableColumnResizing: true,
                    filterVariant,
                    columnFilterModeOptions,
                    filterSelectOptions,
                    size,
                    Cell: ({ cell }) => {
                        if (typeof cell.getValue() === 'boolean') {
                            return cell.getValue() ? intl.formatMessage({ id: "yes" }) : intl.formatMessage({ id: "no" })
                        }
                        //format creation_date
                        if (key === "creation_date") {
                            if (cell.getValue() === null) return "-";
                            const date = new Date(cell.getValue());
                            //convert to DD/Month/YYYY
                            return date.toLocaleDateString('es-ES', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric',
                            });
                        }
                        return cell.getValue();
                    },
                    muiTableBodyCellEditTextFieldProps: editField
                }
            });
            if (data[0]?.survey_name === "No hay datos") {
                setTableData([]);
            } else {
                setTableData(data);
            }
            //delete id column
            columns = columns.filter((c) => c.accessorKey !== "id");
            columns = columns.filter((c) => c.accessorKey !== "store_code");
        }
        setColumns(columns);
        // eslint-disable-next-line 
    }, [data, validationErrors, intl])

    // const csvOptions = {
    //     fieldSeparator: ',',
    //     quoteStrings: '"',
    //     decimalSeparator: '.',
    //     showLabels: true,
    //     useBom: true,
    //     useKeysAsHeaders: false,
    //     headers: columns.map((c) => c.header)
    // };

    const csvExporter = mkConfig({ useKeysAsHeaders: true });
    // const handleExportRows = (rows) => {
    //     csvExporter.generateCsv(rows.map((row) => row.original));
    // };

    const handleExportData = async () => {
        if (tableData.length === 0) return alert("No hay datos para exportar")
        if (window.confirm(intl.formatMessage({ id: "table.survey.export.confirm" }))) {
            //using columnNames changes tableData to an array of objects with only the values of the columns
            const newdata = tableData.map((row) => {
                let newRow = {};
                columnsName && Object.entries(columnsName).forEach(([key, value]) => {
                    newRow[value.header] = row[key];
                });
                return newRow;
            });
            const csv = await generateCsv(csvExporter)(newdata);
            download(csvExporter)(csv)
        }
    };

    const viewSurvey = async (row) => {
        try {
            if (url.includes("?customer")) {
                navigate(`/viewsurvey?survey=${row.original.id}`, {});
            }
        } catch (err) {
            console.error(err);
        }
    }

    const onHoverGetSurvey = async (row) => {
        if (url.includes("?customer")) {
            queryClient.prefetchQuery({
                queryKey: ["surveyQuestions", row.original.id],
                queryFn: async () => await axiosPrivate.get(`survey/info/${row.original.id}?customer=${defaultCustomer}`).then(res => res.data),
                retry: false,
            })
        }
    }

    const deleteRow = async (row) => {
        try {
            if (url.includes("?customer")) {
                if (window.confirm(intl.formatMessage({ id: "delete.item.msg" }))) {
                    deleteSurveyMutation.mutate(row);
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    const onSubmit = async (values) => {
        try {
            if (url.includes("?customer")) {
                createSurveyMutation.mutate(values);
            }
        } catch (err) {
            console.error(err);
        }
    }
    const [openQr, setOpenQr] = useState(false);
    const [qrId, setQrId] = useState("qrId");
    const [qrUlr, setQrUlr] = useState("qrUlr");

    const handleOpenQrDialog = (row) => {
        let idUrl = row.original.store_code ?? row.original.source;
        setOpenQr(true);
        setQrId(`${row.original.source}`);//este es el nombre con el que se descarga el archivo
        if (defaultCustomer.toLowerCase() === "hermeco") {
            setQrUlr(`/user/${defaultCustomer}/${idUrl}`);
            return;
        }
        setQrUlr(`/newsurvey/${defaultCustomer}/${idUrl}`);
    };

    return (
        <div className='mb-3'>
            {
                url.includes("?customer") &&
                <>
                    <QrDialog open={openQr} setOpen={setOpenQr} qrId={qrId} qrUlr={qrUlr} />
                    <CreateSurveyDialog
                        open={openCreate}
                        handleClosedialog={() => {
                            setOpenCreate(false)
                            setOpenDialogSnackBar(false);
                        }}
                        columns={(columns || []).filter((c) => (c.accessorKey !== "count" && c.accessorKey !== "creation_date"))}
                        onSubmit={onSubmit}
                        change={change}
                        openDialogSnackBar={openDialogSnackBar}
                        color={color}
                        msg={msg}
                        validateCreateLimitation={validateCreateLimitation}
                        handleClose={(event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            setOpenDialogSnackBar(false);
                        }}
                    />
                    <MetaDecorator
                        title={`Mis Encuestas - ${defaultCustomer}`}
                        description={"Mis encuestas 7Bytes"}
                        imageUrl={"/images/7Bytes.png"}
                        imageAlt={"Mis encuestas 7Bytes"}
                        path={window.location.pathname}
                    />
                </>
            }
            <SnackBarComponent
                time={3000}
                position={topCenterPosition}
                transition={TransitionDown}
                handleClose={handleClose}
                openSnackBar={openSnackBar}
                color={color}
                msg={msg}
            />
            {
                (columns.length === 0 || tableData === 0) ?
                    <TableLoadingSkeleton isLoading={isLoading} errorMsg={errorMsg} />
                    :
                    <MaterialReactTable
                        key={2}
                        columns={columns}
                        data={tableData}
                        initialState={{
                            pagination: {
                                pageSize: 10,
                            },
                            density: 'comfortable',
                            columnVisibility: { id: false },
                            sorting: [
                                { id: 'creation_date', desc: true }, //sort by state in ascending order by default
                            ]
                        }}
                        state={{
                            isLoading: isLoading,
                            showAlertBanner: errorMsg,
                        }}
                        defaultColumn={{
                            maxSize: 400,
                            minSize: 30,
                            size: 60, //default size is usually 180
                        }}
                        enableStickyHeader
                        columnResizeMode="onEnd"
                        enableSubRowSelection={false}
                        muiTableBodyProps={{
                            sx: {
                                //stripe the rows, make odd rows a darker color
                                '& tr:nth-of-type(odd)': {
                                    backgroundColor: '#f5f5f5',
                                },
                            },
                        }}
                        muiTableBodyCellProps={{
                            sx: {
                                borderRight: '2px solid #e0e0e0', //add a border between columns
                            },
                        }}
                        positionActionsColumn="last" //no funciona
                        enableRowActions
                        renderRowActionMenuItems={({ row, table, closeMenu }) => [
                            <MenuItem disabled={change}
                                key={1}
                                sx={{ m: 0 }}
                                onClick={() => {
                                    viewSurvey(row);
                                    closeMenu();
                                }}
                                onMouseEnter={() => onHoverGetSurvey(row)}
                            >
                                <span>
                                    <Edit color="warning" />&nbsp;
                                    <FormattedMessage
                                        id="edit"
                                        defaultMessage={`Editar`}
                                    />
                                </span>
                            </MenuItem>,
                            !isViewRole && <MenuItem disabled={change}
                                key={3}
                                onClick={() => {
                                    table.setEditingRow(row);
                                    closeMenu();
                                }}
                                sx={{ m: 0 }}
                            >
                                <span>
                                    <i className="box-ico fa fa-cog fa-fw" />
                                    &nbsp;
                                    <FormattedMessage
                                        id="dashboard.settings"
                                        defaultMessage={`Configuración`}
                                    />
                                </span>
                            </MenuItem>,
                            <MenuItem disabled={change}
                                key={2}
                                onClick={() => {
                                    handleOpenQrDialog(row);
                                    closeMenu();
                                }}
                                sx={{ m: 0 }}
                            >
                                <span>
                                    <QrCode2Icon />&nbsp;
                                    <FormattedMessage
                                        id="view.qr.code"
                                        defaultMessage={'Ver codigo QR'}
                                    />
                                </span>
                            </MenuItem>,
                            !isViewRole && <MenuItem disabled={change}
                                key={4}
                                onClick={() => {
                                    deleteRow(row);
                                    closeMenu();
                                }}
                                sx={{ m: 0 }}
                            >
                                <span>
                                    <Delete color="error" />&nbsp;
                                    <FormattedMessage
                                        id="delete"
                                        defaultMessage={`Eliminar`}
                                    />
                                </span>
                            </MenuItem>,
                        ]}
                        editingMode="modal"
                        onEditingRowSave={handleSaveRowEdits}
                        onEditingRowCancel={handleCancelRowEdits}
                        enableColumnResizing
                        enableRowSelection={false}
                        enablePinning={true}
                        enableRowDragging={false}
                        enableColumnOrdering={false}
                        enableColumnFilterModes
                        positionToolbarAlertBanner="bottom"
                        enableFullScreenToggle
                        localization={actionLanguage}
                        muiTableBodyCellCopyButtonProps={{
                            sx: { width: '100%' },
                            startIcon: <ContentCopy />,
                        }}
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [2, 5, 10, 25, 50, 100],
                            showFirstButton: false,
                            showLastButton: false
                        }}
                        muiToolbarAlertBannerProps={
                            errorMsg
                                ? {
                                    color: 'error',
                                    children: intl.formatMessage({ id: "get.data.error.msg" }),
                                }
                                : undefined
                        }
                        renderTopToolbarCustomActions={({ table }) => (
                            !change ?
                                isLoading ?
                                    <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
                                        <Skeleton variant="rectangular" width={200} height={40} />
                                        <Skeleton variant="rectangular" width={180} height={40} />
                                    </Box>
                                    :
                                    <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
                                        {/* <Tooltip arrow title="Refresh Data">
                            <IconButton onClick={() => refetch()}>
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip> */}
                                        {
                                            url.includes("?customer") && !isViewRole &&
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                onClick={() => setOpenCreate(true)}
                                            >
                                                <AddIcon />
                                                {!fullScreen &&
                                                    <FormattedMessage
                                                        id="survey.create.new"
                                                        defaultMessage={`Crear nueva encuesta`}
                                                    />
                                                }
                                            </Button>
                                        }
                                        <Button color="primary"
                                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                            onClick={handleExportData}
                                            variant="contained" >
                                            <FileDownloadIcon style={{ fontSize: "20px" }} />
                                            {!fullScreen &&
                                                <FormattedMessage
                                                    id="table.export.all.data"
                                                    defaultMessage={`Exportar todos los datos`}
                                                />
                                            }
                                        </Button>
                                        {/* <Button
                            disabled={table.getPrePaginationRowModel().rows.length === 0}
                            //export all rows, including from the next page, (still respects filtering and sorting)
                            onClick={() =>
                                handleExportRows(table.getPrePaginationRowModel().rows)
                            }
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export All Rows
                        </Button> */}
                                        {/* <Button
                            disabled={table.getRowModel().rows.length === 0}
                            //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                            onClick={() => handleExportRows(table.getRowModel().rows)}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export Page Rows
                        </Button> */}
                                        {/* <Button disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                            //only export selected rows
                            onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                            startIcon={<FileDownloadIcon />}
                            variant="contained" >
                            <FormattedMessage
                                id="table.export.selected.data"
                                defaultMessage={`Exportar filas seleccionadas`}
                            />
                        </Button> */}
                                    </Box>
                                :
                                <Box className='mt-3' sx={{ width: '100%' }}>
                                    <LinearProgress />
                                </Box>
                        )}
                        renderBottomToolbarCustomActions={({ table }) => (
                            change &&
                            <Box sx={{ width: '63%' }}>
                                <LinearProgress />
                            </Box>
                        )}
                    />
            }
        </div>
    );
};

// Table.propTypes = {
//     columns: PropTypes.arrayOf(PropTypes.object).isRequired,
//     tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
// };
