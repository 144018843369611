import { Alert, Button, Snackbar, TextField } from '@mui/material';
import React, { useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailIcon from '@mui/icons-material/Mail';
import emailjs from '@emailjs/browser';
import { TransitionDown, topCenterPosition } from '../UtilsComponents/SnackBarTransitions';

export const ContactDemo = () => {
    const intl = useIntl();
    const {
        REACT_APP_CONTACT_CELLPHONE,
        REACT_APP_EMAIL_SERVICE_ID,
        REACT_APP_EMAIL_KEY,
        REACT_APP_EMAIL_TEMPLATE_ID
    } = process.env;
    const [transition, setTransition] = React.useState(undefined);
    const [snackBarState, setSnackBarState] = React.useState(topCenterPosition);
    const { vertical, horizontal, open } = snackBarState;
    const [isSending, setIsSending] = React.useState(false);


    const [emailError, setEmailError] = React.useState(false);
    const [emailMsg, setEmailMsg] = React.useState('');

    const form = useRef();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarState({ ...snackBarState, open: false });
    };

    async function contactForm(e) {
        e.preventDefault();
        setIsSending(true);
        var nameValue = document.getElementById("inputName");
        var CompanyNameValue = document.getElementById("inputCompanyName");
        var emailValue = document.getElementById("inputEmail");
        var messageValue = document.getElementById("inputMessage");
        if (nameValue.value === "" || CompanyNameValue.value === "" || emailValue.value === "" || messageValue.value === "") {
            setIsSending(false);
            setEmailError(true);
            setTransition(() => TransitionDown);
            setSnackBarState({ ...snackBarState, open: true });
            setEmailMsg(intl.formatMessage({ id: "all.fields.required" }));
            return;
        }
        const contactURL = `https://api.whatsapp.com/send?phone=${REACT_APP_CONTACT_CELLPHONE}&text=Hola!%20👋%20%20Me%20gustaría%20agendar%20una%20demo%20de%207Bytes.%0AMi%20nombre%20es:%20${nameValue.value}%0AEmpresa:%20${CompanyNameValue.value}%0AEmail:%20${emailValue.value}%0A${messageValue.value}`;
        if (e.target.id === "wsp") {
            window.open(contactURL);
        } else if (e.target.id === "mail") {
            setEmailMsg(intl.formatMessage({ id: "change.plan.msg.email.error" }));
            await emailjs.sendForm(REACT_APP_EMAIL_SERVICE_ID, REACT_APP_EMAIL_TEMPLATE_ID, form.current, REACT_APP_EMAIL_KEY)
                .then((result) => {
                    setEmailError(false);
                    setEmailMsg(intl.formatMessage({ id: "change.plan.msg.email" }));
                    nameValue.value = "";
                    CompanyNameValue.value = "";
                    emailValue.value = "";
                    messageValue.value = "";
                    // console.log(result.text);
                }).catch((error) => {
                    setEmailError(true);
                    setEmailMsg(intl.formatMessage({ id: "change.plan.msg.email.error" }));
                    // console.log(error.text);
                }).finally(() => {
                    setTransition(() => TransitionDown);
                    setSnackBarState({ ...snackBarState, open: true });
                });
        }
        setIsSending(false);
    }
    return (
        <form id="AgendarDemoHome" style={{ padding: "1px 15% 0px 10%" }} ref={form}>
            <br></br>
            <h4 className="card-title">
                <FormattedMessage
                    id="form.demo.title"
                    defaultMessage={`Información de contacto`}
                />
            </h4>
            <br></br>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                TransitionComponent={transition}
                anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}>
                <Alert onClose={handleClose} sx={{ width: '100%' }} severity={!emailError ? "success" : "error"}>
                    {emailMsg}
                </Alert>
            </Snackbar>
            <div className="form-group " >
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="inputName" name='from_name' placeholder="Nombre completo" required></input>
                    <label htmlFor="floatingInputCedula">
                        <FormattedMessage
                            id="form.demo.name"
                            defaultMessage={`Nombre`}
                        />
                    </label>
                </div>
            </div>
            <div className="form-group ">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="inputCompanyName" name='company' placeholder="Nombre de la empresa" required></input>
                    <label htmlFor="floatingInputCedula">
                        <FormattedMessage
                            id="form.demo.company"
                            defaultMessage={`Empresa`}
                        />
                    </label>
                </div>
            </div>
            <div className="form-group ">
                <div className="form-floating mb-3">
                    <input type="email" className="form-control" id="inputEmail" name='reply_to' placeholder="nombre@ejemplo.com" required></input>
                    <label htmlFor="floatingInputemail">
                        <FormattedMessage
                            id="form.demo.email"
                            defaultMessage={`Email`}
                        />
                    </label>
                </div>
            </div>
            <div className="form-group ">
                <div className="form-floating">
                    <TextField
                        id="inputMessage"
                        name='message'
                        style={{ width: "100%", backgroundColor: "white" }}
                        label={
                            <FormattedMessage
                                id="form.demo.message"
                                defaultMessage={`Mensaje`}
                            />
                        }
                        multiline
                        rows={4}
                        required
                        placeholder={
                            intl.formatMessage({ id: "form.demo.message.text" })
                        }
                        InputLabelProps={{
                            style: { color: '#212529' },
                        }}
                    />
                </div>
            </div>
            <br></br>
            <div className="form-group">
                <div >
                    <Button className='demoButton' variant="contained" onClick={contactForm} disabled={isSending} type='submit' id="mail" style={{ backgroundColor: "#3f49cc", marginRight: "10px" }} endIcon={<MailIcon id="mail" />}>
                        {isSending ?
                            <FormattedMessage
                                id="sending.button"
                            />
                            :
                            <FormattedMessage
                                id="demo.button.email"
                            />
                        }
                    </Button>
                    <Button className='demoButton' onClick={contactForm} disabled={isSending} type='submit' id="wsp" variant="contained" color="success" endIcon={<WhatsAppIcon id="wsp" />}>
                        {isSending ?
                            <FormattedMessage
                                id="sending.button"
                            />
                            :
                            <FormattedMessage
                                id="demo.button.whatsapp"
                            />
                        }
                    </Button>
                </div>
            </div>
            <br></br>
        </form>
    )
}
