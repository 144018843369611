import React, { useEffect, useState } from 'react'
import { MainFooter } from '../../../ReuseComponents/MainFooter'
import { FormattedMessage } from 'react-intl'
import { Link, useParams } from 'react-router-dom';
import axios from '../../../../api/axios';
import { QRCodeSVG } from 'qrcode.react';
import { useMediaQuery } from "@mui/material";
import { PostSurveyImage } from './PostSurveyImage';

export const SevenBytesPostSurvey = ({ survey, fontPostSurveyColor, backColor }) => {
    let { id, customer } = useParams();
    customer = customer.toLowerCase();
    const createNewSurveyIds = ["34207ecf-5af4-4b24-b21f-3077d7bd588f", "8d1b231d-6b46-4af2-adaf-63ef56856761"];
    const checkPhoneWidth = useMediaQuery('(max-width:760px)');

    var videoSrc = "";
    var videoTitle = "";

    if (customer === "hermeco") {
        videoSrc = "https://www.youtube.com/embed/PjEXuPbTBsk";
        videoTitle = "Caso de éxito: OFFCORSS | 7Bytes";
    } else {
        videoSrc = "https://www.youtube.com/embed/a65sY5VCT2s";
        videoTitle = "Conoce a 7Bytes: Estudio de mercado continuo";
    }

    //#region Validation QR
    const [qrValue, setQrValue] = useState("");
    const [errorQR, setErrorQR] = useState("");
    useEffect(() => {
        const getQr = async () => {
            try {
                const response = await axios.get(`store/promo/${id}?customer=${customer}`);
                if (response.data?.code) {
                    setQrValue(response.data?.code);
                    // setLoadingQR(false);
                }
            } catch (error) {
                // console.error(error);
                if (error.response?.status === 404) {
                    setErrorQR("Lo sentimos, no hay códigos de promoción");
                } else {
                    setErrorQR("No se pudo obtener la información");
                }
            }
        };
        customer === "hermeco" && getQr();
        // eslint-disable-next-line
    }, []);
    //#endregion

    //#region convert svg to png and download
    const hola = () => {
        const svgs = document.getElementById("gogo");
        const svgSize = svgs.getBoundingClientRect();
        //convert svg to png
        var svg = document.getElementById("gogo");
        var svgData = new XMLSerializer().serializeToString(svg);
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        canvas.height = svgSize.height;
        canvas.width = svgSize.width;
        var img = document.createElement("img");
        img.setAttribute("src", "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgData))));
        img.onload = function () {
            ctx.drawImage(img, 0, 0);
            var canvasdata = canvas.toDataURL("image/png");
            var pngimg = '<img id="imgPng" hidden src="' + canvasdata + '">';
            document.getElementById("Ga").innerHTML = pngimg;
            //download png
            const a = document.createElement('a');
            a.href = document.getElementById("imgPng").src;
            a.download = 'newesttree.png';
            a.click();
        };
    }
    //#endregion

    return (
        <>
            <section id="hirev2" style={{ paddingTop: "2%" }}>
                {(typeof survey.imglink === 'string') && checkPhoneWidth && <PostSurveyImage openImageLink={survey?.openImageLink} imglink={survey?.imglink} />}
                {(!checkPhoneWidth || !(typeof survey.imglink === 'string')) &&
                    <div className="container" align="center">
                        <a target="_blank" style={{ outline: "none" }} rel="noreferrer" href="https://www.7-bytes.com/">
                            <img className="img1" alt="Logo" src="/images/7Bytes sin slogan.png" border="0" />
                        </a>
                    </div>
                }
                <div className="container" style={{ borderRadius: "20px", minHeight: "480px" }}>
                    <div>
                        <div className="tdBody" style={{ padding: "0" }} align="center"  >
                            <div className="" border="0" cellPadding="0" cellSpacing="0" >
                                <div>
                                    {survey.status === "4" &&
                                        (
                                            <>
                                                {survey.name ?
                                                    <h1 className="masthead-subheading mt-5 postmsg" style={{ color: fontPostSurveyColor }}>
                                                        <FormattedMessage
                                                            id="complete.survey.msg.name"
                                                            values={
                                                                {
                                                                    name: survey.name
                                                                }
                                                            }
                                                        />
                                                    </h1>
                                                    :
                                                    <h1 className="masthead-subheading mt-5 postmsg" style={{ color: fontPostSurveyColor }}>
                                                        <FormattedMessage
                                                            id="complete.survey.msg"
                                                        />
                                                    </h1>
                                                }
                                                {
                                                    createNewSurveyIds.includes(survey?.survey_id) &&
                                                    <>
                                                        <br />
                                                        <Link
                                                            to={`/${customer === "hermeco" ? "user" : "newsurvey"}/${customer}/${survey?.survey_source}`}
                                                            style={
                                                                {
                                                                    color: "whitesmoke",
                                                                    backgroundColor: "#1d999e",
                                                                }
                                                            }
                                                            className="btn btn-info"
                                                        >
                                                            <FormattedMessage
                                                                id="create.new.survey"
                                                            />
                                                        </Link>
                                                        <br />
                                                    </>
                                                }
                                                {qrValue !== "" ?
                                                    <>
                                                        <h4> Guarda tu código dando clic en la imagen y preséntalo en las taquillas del parque Salitre Mágico. Aplica TyC. </h4>
                                                        <QRCodeSVG value={qrValue} includeMargin={true} size={128} level="H" id='gogo' onClick={hola} />
                                                        <div id="Ga"></div>
                                                    </>
                                                    :
                                                    <div>
                                                        <h4>{errorQR !== "No Qr" ? errorQR : null}</h4>
                                                    </div>
                                                }
                                                <br></br>
                                                {(typeof survey.imglink === 'string') ?
                                                    <div className="wrapper">
                                                        {!checkPhoneWidth && <PostSurveyImage openImageLink={survey.openImageLink} imglink={survey.imglink} />}
                                                        {/* <div>
                                                            <a href={survey.openImageLink} target='_blank' rel='noreferrer'>
                                                                <img className="img-container-postSurvey" src={survey.imglink} alt='PostsurveyImage' />
                                                            </a>
                                                        </div> */}
                                                        {/* SVG Img */}
                                                        {!checkPhoneWidth ?
                                                            <div className="svg-image" >
                                                                <div className="button-container btn-primary text-uppercase">
                                                                    <Link to="/contact">
                                                                        <button>Descubre cómo</button>
                                                                    </Link>
                                                                </div>
                                                            </div> :
                                                            <>
                                                                <a target="_blank" style={{ outline: "none" }} rel="noreferrer" href="https://www.7-bytes.com/">
                                                                    <img className="img1" style={{ width: "130px" }} alt="Logo" src="/images/7Bytes sin slogan.png" border="0" />
                                                                </a>
                                                                <br />
                                                                <Link to="/contact" className='btn btn-primary center' style={{ width: "160px", backgroundColor: "#10979d", fontFamily: "Roboto", fontWeight: "700", fontSize: "1.125rem" }}>
                                                                    Descubre cómo
                                                                </Link>
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    <div className="wrapper">
                                                        {/* Youtube Video */}
                                                        <div className="team-member" style={{ position: 'relative', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', padding: "0px 2% 0px 2%" }} >
                                                            <iframe style={{ borderRadius: "5px" }} width="100%" height="400" src={videoSrc} title={videoTitle} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                        </div>
                                                        {/* SVG Img */}
                                                        <div className="svg-image" >
                                                            <div className="button-container btn-primary text-uppercase">
                                                                <Link to="/contact">
                                                                    <button>Descubre cómo</button>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        )
                                    }
                                </div>
                                {/* <AdComponent dataAdSlot="9228488024" path={window.location.href} /> */}
                            </div>
                        </div>
                    </div>
                </div >
            </section>
            <MainFooter backColor={backColor} />
        </>
    )
}
